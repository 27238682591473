import React from 'react'
import PropTypes from 'prop-types'
import quickNav from 'src/layouts/Dashboard/quickNav'
import Button from "@material-ui/core/Button"
import {makeStyles} from "@material-ui/styles"
import DashboardIcon from "@material-ui/icons/DashboardOutlined"
import Typography from "@material-ui/core/Typography"
import withStyles from "@material-ui/core/styles/withStyles"
import {colors} from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import __ from "../LanguageHelper"
import {NavLink as RouterLink} from "react-router-dom"

const useStyles = makeStyles((theme) => ({
  button: {
    height: 95,
    marginRight: theme.spacing.unit,
    width: '100%'
  },
  label: {
    flexDirection: 'column'
  },
  icon: {
    fontSize: '32px !important',
    marginBottom: theme.spacing.unit,
  }
}))

const ColorButton = withStyles((theme) => ({
  root: {
    color: props => props.color ? colors[props.color][500] : colors.blue[500],
    borderColor: props => props.color ? colors[props.color][500] : colors.blue[500],
    '&:hover': {
      backgroundColor: props => props.color ? colors[props.color][700] : colors.blue[700],
      color: props => theme.palette.getContrastText(props.color ? colors[props.color][500] : colors.blue[500]),
    },
  },
}))(Button)

const Shortcuts = () => {
  const classes = useStyles()

  return (
    <>
      <Typography variant={"h5"} style={{marginBottom: 20}}>
        {__('اختصارات سريعة', 'Quick Shortcuts')}
      </Typography>

      {quickNav.map(group => (
        <>
          <Typography variant={"h6"} style={{color: '#747474', fontWeight: 500, marginBottom: 10, marginTop: 20}}>
            {group.subheader}
          </Typography>

          <Grid container spacing={1}>
            {group.items.map(item => {
              let Icon = item.icon
              return (
                <Grid item xs={6} sm={4} md={3}>
                  <ColorButton
                    component={RouterLink}
                    to={item.href}
                    classes={{root: classes.button, label: classes.label}}
                    variant="outlined"
                    startIcon={<Icon className={classes.icon}/>}
                    size={'small'}
                    color={item.color}
                  >
                    {item.title}
                  </ColorButton>
                </Grid>
              )
            })}
          </Grid>
        </>
      ))}

      <br />
      <br />
    </>
  )
}

export default Shortcuts
