import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/styles'
import {Controller} from "react-hook-form"
import {TwitterPicker} from "react-color"
import InputLabel from "@material-ui/core/InputLabel"
import FormLabel from "../../FormLabel"
import {Field} from "react-final-form"
import _ from 'lodash'
import Rating from "@material-ui/lab/Rating"
import Box from "@material-ui/core/Box"

const useStyles = makeStyles((theme) => ({

}))

const FormRate = ({field, title, value, required}) => {
  const classes = useStyles()
  return (
    <>
      <FormLabel title={title} isFilter={true}/>
      <Box my={1} />
      <Field name={field} required={required}>
        {props => (
          <div>
            <Rating
              size={'large'}
              className={classes.rating}
              name={field}
              defaultValue={value}
              precision={1}
              {...props.input}
            />
          </div>
        )}
      </Field>
    </>
  )
}

FormRate.propTypes = {
  field: PropTypes.string,
  type: PropTypes.string,
  title: PropTypes.any,
}

export default FormRate
