/* eslint-disable no-undef */
import React from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from "@material-ui/styles"

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  }
}))

function Space({width, ...rest}) {
  return (
    <span style={{width, display: 'inline-table'}}/>
  )
}

Space.propTypes = {
  children: PropTypes.node,
  width: PropTypes.number
}

export default Space
