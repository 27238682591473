import React, {useRef, useState} from 'react'
import {Box, Button, List, ListItem, ListItemText, makeStyles} from '@material-ui/core'
import {DetailsCard, Page} from "src/admin-utils"
import ReactToPrint from "react-to-print"
import __ from "../../../LanguageHelper"
import {SortableContainer, SortableElement, SortableHandle} from "react-sortable-hoc"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import PerfectScrollbar from "react-perfect-scrollbar"
import arrayMove from "array-move"
import {postRequest} from "../../../API"
import {useSelector} from "react-redux"
import {GridLoader} from "react-spinners"
import {theme} from "../../../../theme"
import LoadingOverlay from "react-loading-overlay"
import IconButton from "@material-ui/core/IconButton"
import _ from 'lodash'

const useStyles = makeStyles((theme) => ({
  '@global': {
    '.sortableItem': {
      zIndex: 99999999
    }
  },
  container: {
    maxHeight: 'calc(100vh - 150px)',
    overflow: 'scroll'
  },
  saveButton: {
    top: 5
  }
}))

const DragHandle = SortableHandle(() => (
  <ListItemIcon>
    <DragIndicatorIcon/>
  </ListItemIcon>
))

const SortableItem = SortableElement(({value}) => {
  return (
    <ListItem ContainerComponent="div">
      <DragHandle/>
      <ListItemText primary={value}/>
    </ListItem>
  )
})

const SortableList = SortableContainer(({items, sorterLabel}) => {
  return (
    <List component="div">
      {items.map((item, index) => (
        <SortableItem
          key={`item-${item.id}`}
          index={index}
          value={item[sorterLabel]}
        />
      ))}
    </List>
  )
})

function SortingPanel({data, startIndex, sorterLabel, endpoint, onLoad}) {
  const classes = useStyles()
  const [items, setItems] = useState(data)
  const {token} = useSelector((state) => state.session)
  const [loading, setLoading] = useState(false)

  function saveSort() {
    setLoading(true)
    postRequest(`${endpoint}/save-sort`, {
      startIndex,
      ids: items.map(i => i.id),
      object: items[0].object
    }, token).then(() => {
      onLoad && onLoad()
      setLoading(false)
    })
  }

  const onSortEnd = ({oldIndex, newIndex}) => {
    setItems(items => arrayMove(items, oldIndex, newIndex))
  }

  return (
    <DetailsCard
      title={__('اعادة ترتيب', 'Sorting')}
      action={(
        <Button onClick={() => saveSort()} color={'primary'} className={classes.saveButton}>
          {__('حفظ الترتيب', 'Save Order')}
        </Button>
      )}
    >
      <LoadingOverlay
        active={loading}
        spinner={<GridLoader color={theme.palette.primary.main} size={10}/>}
      >
        <PerfectScrollbar>
          <Box className={classes.container}>
            <SortableList
              items={items}
              sorterLabel={sorterLabel}
              onSortEnd={onSortEnd}
              lockAxis="y"
              useDragHandle={true}
              lockToContainerEdges={true}
              helperClass={'sortableItem'}
            />
          </Box>
        </PerfectScrollbar>
      </LoadingOverlay>
    </DetailsCard>
  )
}

export default SortingPanel
