import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/styles'
import {Controller} from "react-hook-form"
import {KeyboardDatePicker, TextField} from 'mui-rff'
import moment from "moment"
import FormLabel from "../../FormLabel"

const useStyles = makeStyles(() => ({
  field: {

  }
}))

const FormMonth = ({field, title, isFilter, required}) => {
  const classes = useStyles()

  return (
    <>
      <FormLabel title={title} isFilter={isFilter} />
      <KeyboardDatePicker
        required={required}
        name={field}
        fullWidth
        label={!isFilter && title}
        placeholder={isFilter && ''}
        disableToolbar
        variant="inline"
        inputVariant="outlined"
        format="MM/YYYY"
        margin="none"
        size={'small'}
        views={["month", "year"]}
        autoOk
      />
    </>
  )
}

FormMonth.propTypes = {
  field: PropTypes.string,
  type: PropTypes.string,
  title: PropTypes.any,
}

export default FormMonth
