import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/styles'
import {KeyboardTimePicker,} from 'mui-rff'
import FormLabel from "../../FormLabel"
import moment from "moment"

const useStyles = makeStyles(() => ({
  field: {}
}))

const FormTime = ({field, title, isFilter, required}) => {

  return (
    <>
      <FormLabel title={title} isFilter={isFilter}/>
      <KeyboardTimePicker
        required={required}
        name={field}
        fullWidth
        label={!isFilter && title}
        placeholder={isFilter && title}
        variant="inline"
        inputVariant="outlined"
        margin="none"
        size={'small'}
        autoOk
      />
    </>
  )
}

FormTime.propTypes = {
  field: PropTypes.string,
  type: PropTypes.string,
  title: PropTypes.any,
}

export default FormTime
