import React, {Component, useState} from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/styles'
import {Button, colors, Modal, Tooltip} from "@material-ui/core"
import Drawer from "@material-ui/core/Drawer"
import AddIcon from "@material-ui/icons/Add"
import EditIcon from "@material-ui/icons/Edit"
import Form from "./Form"
import AccessGuard from "../AccessGuard"
import {If, Then, Else} from 'react-if'
import IconButton from "@material-ui/core/IconButton"
import _ from 'lodash'
import withStyles from "@material-ui/core/styles/withStyles"

const useStyles = makeStyles((theme) => ({
  buttonTitle: {
    marginLeft: 10
  }
}))

const Icon = ({icon}) => {
  if(_.isString(icon)) {
    return icon === "edit" ? <EditIcon/> : <AddIcon/>
  }

  return icon || null
}

const ColorButton = withStyles((theme) => ({
  root: {
    color: props => theme.palette.getContrastText(props.color ? colors[props.color][500] : theme.palette.primary.main),
    backgroundColor: props => props.color ? colors[props.color][500] : theme.palette.primary.main,
    '&:hover': {
      backgroundColor: props => props.color ? colors[props.color][700] : theme.palette.primary.dark,
    },
  },
}))(Button)

class Forms extends Component {

  state = {
    visible: false,
    id: false,
    values: {}
  }

  constructor(props) {
    super(props)

    if (props.id) {
      this.state = {
        visible: false,
        id: props.id,
        values: {}
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.onRef != null) {
      this.props.onRef(this)
    }
  }

  componentDidMount() {
    if (this.props.onRef != null) {
      this.props.onRef(this)
    }

    // console.log(this.props.hidden)
  }

  getValue = key => {
    const {values} = this.state
    return values[key] || false
  }

  show = (id) => {
    this.setState({visible: true, id})
  }

  handleModalClose = () => {
    this.setState({visible: false})
  }

  render() {
    const {title, type = 'modal', hide, icon, color, iconOnly, show = true, id: recordId} = this.props
    const {visible, id} = this.state

    const RenderFormTrigger = () => {
      const classes = useStyles()

      return (
        <AccessGuard permission={'add'}>
          <If condition={iconOnly}>
            <Then>
              <Tooltip title={title}>
                <IconButton
                  color="primary"
                  variant="contained"
                  onClick={() => this.show(id)}
                >
                  <Icon icon={icon} />
                </IconButton>
              </Tooltip>
            </Then>
            <Else>
              <ColorButton
                color={color}
                variant="contained"
                onClick={() => recordId ? this.show(id) : this.show()}
              >
                <Icon icon={icon} /> <span className={classes.buttonTitle}>{title}</span>
              </ColorButton>
            </Else>
          </If>
        </AccessGuard>
      )
    }

    const RenderForm = () => {
      const classes = useStyles()
      const {width} = this.props

      if (type === 'inline') {
        return (
          <Form {...this.props} inline id={id}/>
        )
      }

      if (type === 'drawer') {
        return (
          <Drawer
            anchor="right"
            classes={{paper: {width: width || 500, maxWidth: '100%'}}}
            open={visible}
            variant="temporary"
          >
            <Form {...this.props} id={id} handleModalClose={() => this.handleModalClose()}/>
          </Drawer>
        )
      }

      return (
        <>
          <Modal
            onClose={() => this.handleModalClose()}
            open={visible}
          >
            <Form {...this.props} id={id} modal handleModalClose={() => this.handleModalClose()}/>
          </Modal>
        </>
      )
    }

    if(!show) return null

    return (
      <>
        {(!hide && type !== 'inline') && <RenderFormTrigger/>}
        {(visible || type === 'inline') && <RenderForm/>}
      </>
    )
  }
}

Forms.propTypes = {
  title: PropTypes.any,
  fields: PropTypes.array,
  inline: PropTypes.bool,
  type: PropTypes.string,
  endpoint: PropTypes.string.isRequired,
  params: PropTypes.object,
  hidden: PropTypes.object,
}

export default Forms
