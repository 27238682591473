import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/styles'
import {Controller} from "react-hook-form"
import FormLabel from "../../FormLabel"
import {Radios} from "mui-rff"

const useStyles = makeStyles(() => ({
  container: {
    flexDirection: 'row'
  }
}))

const FormRadios = ({field, title, options, isFilter, required}) => {
  const classes = useStyles()

  const [formattedOptions, setOptions] = useState([])

  // Format options
  useEffect(() => {
    let formattedOptions = []
    options.map(option => {
      formattedOptions.push({
        label: option.name,
        value: option.id
      })
    })

    setOptions(formattedOptions)
  }, [])

  return (
    <>
      <FormLabel title={title} isFilter={isFilter}/>
      <Radios
        required={required}
        label={title}
        name={field}
        data={formattedOptions}
        radioGroupProps={{className: classes.container}}
      />
    </>
  )
}

FormRadios.propTypes = {
  field: PropTypes.string,
  type: PropTypes.string,
  title: PropTypes.any,
}

export default FormRadios
