import apisauce from 'apisauce'
import {baseUrl} from '../utils/globals'
import _ from 'lodash'
import swal from "sweetalert"
import __ from "./LanguageHelper"

const language = __('ar', 'en')

const createApi = () => {
  return apisauce.create({
    baseURL: baseUrl,
    headers: {
      'Cache-Control': 'no-cache',
      "Accept-Language": language
    },
    timeout: 3000000
  })
}

const handleResponse = (response) => {
  let data = response.data

  if (!data) {
    console.log("Error, No Data")
    return false
  }

  if (data.status === "error" && data.status_code === 401) {
    window.location.replace("/auth/login")
    return
  }

  // console.log(response)

  if ((data.errors) || (data.status === "error" && data.status_code === 422)) {
    swal({
      title: __('خطأ', 'Error'),
      text: Object.values(data.errors).join('\n'),
      icon: "error",
      button: __('حسنا', 'OK'),
    }).finally()

    return false
  }

  if (data.status === "error" && data.status_code === 417) {
    swal({
      title: __('خطأ', 'Error'),
      text: data.message,
      icon: "error",
      button: __('حسنا', 'OK'),
    }).finally()
    return false
  }

  if (response.status === 417) {
    swal({
      title: __('خطأ', 'Error'),
      text: data.message,
      icon: "error",
      button: __('حسنا', 'OK'),
    }).finally()
    return false
  }

  if (response.status === 422 || response.status === 500) {
    swal({
      title: __('خطأ', 'Error'),
      text: data.message,
      icon: "error",
      button: __('حسنا', 'OK'),
    }).finally()
    return false
  }

  return data
}

const getData = async (endpoint, params, token, noTenant = false) => {
  const tenant_id = localStorage.getItem('tenant_id')
  const api = createApi()
  if (_.isObject(endpoint)) {
    endpoint = endpoint.get
  }

  params.language = language

  if(tenant_id > 0 && !noTenant) {
    params.tenant_id = tenant_id
  }

  if(!params.requestedBy) {
    params.requestedBy = 'dashboard'
  }

  let response = await api.get(endpoint, params, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      "Accept-Language": language
    }
  })

  return handleResponse(response)
}

const getDetails = async (endpoint, id, token, params) => {
  const tenant_id = localStorage.getItem('tenant_id')
  const api = createApi()
  let url = `${endpoint}`

  if (id) {
    url += `/${id}`
  }

  if (params) {
    params.language = language
  } else {
    params = {language}
  }

  if(tenant_id > 0) {
    params.tenant_id = tenant_id
  }

  let response = await api.get(url, params, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      "Accept-Language": language
    }
  })

  return handleResponse(response)
}

const postRequest = (endpoint, params = {}, token, method = 'post', normal = false, headers = {}, noTenant = false) => {
  const tenant_id = localStorage.getItem('tenant_id')

  const api = createApi()
  let data = new FormData()
  Object.keys(params).map((param, i) => {
    let key = Object.keys(params)[i],
      value = Object.values(params)[i]

    if (value instanceof Array) {
      value.map((item) => {
        data.append(`${key}[]`, item)
      })
    } else {
      data.append(key, value)
    }
  })

  data.append('language', language)

  if(tenant_id > 0 && !noTenant) {
    data.append('tenant_id', tenant_id)
  }

  if(normal) {
    data = params
    data.language = language
  }

  if(tenant_id > 0 && !noTenant) {
    data.tenant_id = tenant_id
  }

  return api[method](endpoint, data, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      ...headers
    },
    timeout: 0
  })
}

const create = async (endpoint, data, token) => {
  let response = await postRequest(endpoint, data, token)
  return handleResponse(response)
}

const update = async (endpoint, id, data, token) => {
  let response = await postRequest(`${endpoint}/${id}`, data, token)
  return handleResponse(response)
}

const remove_record = async (endpoint, id, token) => {
  if (_.isObject(endpoint)) {
    endpoint = endpoint.delete
  }
  await postRequest(`${endpoint}/${id}`, {}, token, 'delete')
}

export {
  getData,
  create,
  update,
  getDetails,
  remove_record,
  postRequest,
}
