import Typography from "@material-ui/core/Typography"
import {FormattedMessage} from "react-intl"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import Divider from "@material-ui/core/Divider"
import Drawer from "@material-ui/core/Drawer"
import React, {memo} from "react"
import {makeStyles} from "@material-ui/styles"
import FormRender from "../../Forms/FormRender"
import {Button, CardActions} from "@material-ui/core"
import __, {lang} from "../../../LanguageHelper"
import {Form as FinalForm} from "react-final-form"
import '../../Forms/Forms.scss'
import _ from 'lodash'
import clsx from "clsx"
import Box from "@material-ui/core/Box"
import adminUtils from "../../../../locales/adminUtils"

const drawerWidth = 400

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    maxWidth: '100%',
  },
  drawerPaper: {
    width: drawerWidth,
    zIndex: theme.zIndex.drawer + 200,
    maxWidth: '100%'
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    justifyContent: 'flex-end',
  },
  filterTitle: {
    padding: theme.spacing(0, 3),
    marginTop: -25
  },
  formContainer: {
    paddingTop: theme.spacing(2),
    flex: 1
  },
  filterFormContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingRight: 20
  },
  fieldsContainer: {
    flex: 1
  },
  inlineFilterButton: {
    height: 37,
    top: 24,
    maxWidth: 140,
    marginRight: 10
  },
  buttonContainer: {
    width: lang(265, 265)
  }
}))

const FilterPanel = memo(({
                            filterOpen,
                            toggleFilter,
                            fields = [],
                            applyFilter,
                            filters,
                            inline,
  hideReset = false,
                            customClasses = {container: {}, buttonContainer: {}, formRenderContainer: {}}
                          }) => {

  const classes = useStyles()

  const processData = (values) => {
    let data = []
    // console.log(values)

    Object.keys(values).map(key => {
      let field = flattedFields().find(f => f.field === key)

      // Ignore search text key
      if (!key.includes('_search_text')) {
        let fieldData = {key}
        fieldData.filter = field.filter || '='

        if(field.param) {
          fieldData.param = true
        }

        if (values[key]) fieldData.value = values[key]

        // Add only if has value
        if (fieldData.value && (_.isInteger(fieldData.value) || fieldData.value.length > 0)) {
          data.push(fieldData)
        }

        // Dates
        if (fieldData.value && fieldData.value.isValid && fieldData.value.isValid()) {
          fieldData.value = fieldData.value.format("YYYY-MM-DD 00:00:00")
          data.push(fieldData)
        }
      }

    })

    // console.log(data)

    if (data.length) {
      applyFilter && applyFilter(data)
    }
  }

  const onSubmit = async values => {
    console.log(values)
  }

  // Mutators
  /** Clears a form value */
  const clear = ([name], state, {changeValue}) => {
    changeValue(state, name, () => undefined)
  }

  const flattedFields = () => {
    let flattenFields = []
    formattedFields().map((field) => {
      if (Array.isArray(field)) {
        field.map((subField) => {
          flattenFields.push(subField)
        })
      } else {
        flattenFields.push(field)
      }
    })

    return flattenFields
  }

  const initialValues = () => {
    // Set default values
    let initialValues = {}
    flattedFields().map(field => {
      let filter = Object.keys(filters).length > 0 && filters.find(f => f.key === field.field)
      if (filter) {
        initialValues[field.field] = filter.value
      } else {
        if (field.multiple) {
          initialValues[field.field] = []
        }
      }
    })

    // console.log(filters, initialValues)

    return initialValues
  }

  const formattedFields = () => {
    let formattedFields = []
    fields.map((field) => {
      let formattedField = field
      if (field.filter === "range") {
        formattedField = [
          {...field, field: `${field.field}_from`, title: `${field.title} (${__('من', 'from')})`},
          {...field, field: `${field.field}_to`, title: `(${__('الى', 'to')})`}
        ]
      }

      formattedFields.push(formattedField)
    })

    return formattedFields
  }

  const formRender = (form) => {
    return (
      <FormRender
        className={clsx(customClasses.formRenderContainer)}
        isFilter={!inline}
        fields={formattedFields()}
        form={form}
      />
    )
  }

  if (inline) {
    return (
      <FinalForm
        mutators={{clear}}
        onSubmit={onSubmit}
        initialValues={initialValues()}
        render={({form, handleSubmit, reset, submitting, pristine, values}) => {
          return (
            <div className={clsx(classes.filterFormContainer, customClasses.container)}>
              <div className={classes.fieldsContainer}>
                {formRender(form)}
              </div>

              <Box className={clsx(classes.buttonContainer, customClasses.buttonContainer)}>
                <Button
                  className={classes.inlineFilterButton}
                  variant="contained"
                  color={'secondary'}
                  onClick={() => processData(values)}
                >
                  {adminUtils.table.filterButtonText}
                </Button>

                {!hideReset && <Button
                  className={classes.inlineFilterButton}
                  fullWidth
                  variant="outlined"
                  onClick={() => {
                    form.reset()
                    applyFilter && applyFilter([])
                  }}
                >
                  {__('إعادة تعيين', 'Reset')}
                </Button>}
              </Box>
            </div>
          )
        }}
      />
    )
  }

  return (<Drawer
    className={classes.drawer}
    variant="persistent"
    anchor="left"
    open={filterOpen}
    classes={{
      paper: classes.drawerPaper,
    }}
  >
    <FinalForm
      mutators={{clear}}
      onSubmit={onSubmit}
      initialValues={initialValues()}
      render={({form, handleSubmit, reset, submitting, pristine, values}) => {
        return (
          <form noValidate>
            <div className={classes.drawerHeader}>
              <IconButton onClick={toggleFilter}>
                <CloseIcon/>
              </IconButton>
            </div>

            <div className={classes.filterTitle}>
              <Typography variant={"h4"}>
                {__('بحث و فلترة', 'Search & Filter')}
              </Typography>
              <Typography variant={"body2"}>
                {__('سيتم تطبيق الفلاتر علي الجدول', 'Filters will be applied to table')}
              </Typography>
            </div>

            <div className={classes.formContainer}>
              {filterOpen && formRender(form)}
            </div>

            <Divider/>

            <CardActions>
              <Button
                fullWidth
                variant="contained"
                color={'secondary'}
                onClick={() => processData(values)}
              >
                {adminUtils.table.filterButtonText}
              </Button>

              <div style={{width: 180}}>
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={() => {
                    form.reset()
                    applyFilter && applyFilter([])
                    toggleFilter()
                  }}
                >
                  {__('إعادة تعيين', 'Reset')}
                </Button>
              </div>
            </CardActions>
          </form>
        )
      }}
    />
  </Drawer>)
}, (prevProps, nextProps) => prevProps.filterOpen === nextProps.filterOpen)

export default FilterPanel
