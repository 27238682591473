import React, {useRef, useState} from 'react'
import {Box, Button, ButtonGroup, CardHeader, Divider, Input, makeStyles, Paper, Radio, TextField} from '@material-ui/core'
import {Page} from "src/admin-utils"
import ReactToPrint from "react-to-print"
import __ from "../../../LanguageHelper"
import clsx from "clsx"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import TableBody from "@material-ui/core/TableBody"
import Checkbox from "@material-ui/core/Checkbox"
import _ from "lodash"
import Table from "@material-ui/core/Table"
import {tableOptions} from "../../../../utils/globals"

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  input: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
  }
}))

function ExportPanel({title, headers, onDone}) {
  const classes = useStyles()
  const [selectedItems, setSelectedItems] = useState(headers)
  const [reportTitle, setTitle] = useState(title)
  const [items, setItems] = useState(headers)

  const startExport = () => {
    onDone({
      headers: selectedItems,
      title: reportTitle
    })
  }

  const handleChange = (e) => {
    setTitle(e.target.value)
  }

  function onSelectItem(event, item) {
    const isChecked = event.target.checked
    let modifiedItems = [...selectedItems, item]

    if (!isChecked) {
      modifiedItems = [...selectedItems].filter(i => i.field !== item.field)
    }

    setSelectedItems(modifiedItems)
  }

  const changeTotal = (item, value) => {
    let modifiedItems = [...items]

    modifiedItems.map((i, index) => {
      if(i.field === item.field) {
        modifiedItems[index].total = value
      }
    })

    setItems(modifiedItems)
  }

  return (
    <Box>
      <CardHeader
        title={__('إعدادات التصدير', 'Export Options')}
        className={classes.headerLight}
        action={
          <Button
            size={"small"}
            variant="contained"
            color={'secondary'}
            onClick={startExport}
          >
            {__('تصدير', 'Export')}
          </Button>
        }
      />
      <Divider/>

      <Box p={2}>
        <Input
          className={classes.input}
          disableUnderline
          onChange={handleChange}
          label={__('عنوان التقرير', 'Report Title')}
          value={reportTitle}
          margin="none"
          fullWidth
          variant="outlined"
        />

        <Table size={'small'}>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox"/>
              <TableCell><strong>{__('العمود', 'Column')}</strong></TableCell>
              <TableCell align="center"><strong>{__('إجماليات', 'Total')}</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item) => {
              const isSelected = selectedItems.find(i => i.field === item.field)
              if(_.get(tableOptions, 'defaultExport') && item.field !== 'serial') {
                item.total = 'sum'
              }

              return (
                <TableRow key={item.id}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={isSelected}
                      onChange={(event) => onSelectItem(event, item)}
                    />
                  </TableCell>
                  <TableCell>{item.title}</TableCell>
                  <TableCell padding="checkbox" align="center">
                    {(item.type === 'number' || item.type === 'money') && <ButtonGroup color="primary" size={'small'}>
                      <Button
                        onClick={() => changeTotal(item, 'none')}
                        variant={(item.total === 'none' || !item.total) ? 'contained' : 'outlined'}>
                        {__('بدون', 'None')}
                      </Button>
                      <Button
                        onClick={() => changeTotal(item, 'sum')}
                        variant={(item.total === 'sum') ? 'contained' : 'outlined'}>
                        {__('تجميع', 'SUM')}
                      </Button>
                      <Button
                        onClick={() => changeTotal(item, 'avg')}
                        variant={item.total === 'avg' ? 'contained' : 'outlined'}>
                        {__('متوسط', 'AVG')}
                      </Button>
                    </ButtonGroup>}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>

      </Box>
    </Box>
  )
}

export default ExportPanel
