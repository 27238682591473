import InputLabel from "@material-ui/core/InputLabel"
import React from "react"
import {makeStyles} from "@material-ui/styles"

const useStyles = makeStyles((theme) => ({
  label: {
    marginBottom: theme.spacing(1),
    fontWeight: 600
  }
}))

const FormLabel = ({title, isFilter}) => {
  const classes = useStyles()

  if (!isFilter) return null
  return (
    <InputLabel className={classes.label}>{title}</InputLabel>
  )
}

export default FormLabel
