import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/styles'
import {Button, Typography} from "@material-ui/core"
import {useDropzone} from 'react-dropzone'
import {Field} from "react-final-form"
import __ from "../../../../LanguageHelper"
import BackupIcon from '@material-ui/icons/Backup'
import _ from 'lodash'
import {If, Then, Else} from "react-if"
import {RiCloseLine, RiImageEditLine} from "react-icons/all"
import {formOptions} from "../../../../../utils/globals"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import TableBody from "@material-ui/core/TableBody"
import Table from "@material-ui/core/Table"
import DeleteIcon from "@material-ui/icons/DeleteOutlined"

const useStyles = makeStyles((theme) => ({
  field: {},
  input: {
    display: 'none',
  },
  filename: {
    marginTop: theme.spacing(1)
  },
  img: {
    maxWidth: 120,
    maxHeight: 120,
    display: 'block',
    width: 'auto',
    height: '100%'
  },
  image_container: {
    marginBottom: theme.spacing(1)
  },
  thumb: {
    display: 'inline-flex',
    width: 25,
    height: 25,
    borderRadius: 5,
    boxSizing: 'border-box',
    textAlign: 'center'
  },
  dropZone: {
    display: "flex",
    width: "100%",
    border: "1px dashed #afafaf",
    height: 90,
    alignItems: "center",
    borderRadius: 10,
    justifyContent: "center",
    textAlign: "center",
    cursor: "pointer",
    color: "#afafaf",
    outline: 'none',
    flexDirection: 'column'
  },
  avatarDropZone: {
    display: "flex",
    width: 90,
    height: 90,
    border: "1px dashed #afafaf",
    alignItems: "center",
    borderRadius: 45,
    justifyContent: "center",
    textAlign: "center",
    cursor: "pointer",
    color: "#afafaf",
    outline: 'none',
    flexDirection: 'column'
  },
  avatarThumb: {
    width: 90,
    height: 90,
    borderRadius: 45,
  },
  container: {
    marginTop: 5
  },
  filesContainer: {
    display: "flex",
    flexDirection: 'row',
    backgroundColor: "#f5f5f5",
    paddingRight: 10,
    paddingLeft: 10,
    borderRadius: 5,
    marginLeft: 1,
    marginBottom: 1,
  },
  fileDetails: {
    paddingTop: 10,
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  selectedFiles: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: 7,
  },
  fileThumb: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    width: 25,
    height: 25,
    borderRadius: 5,
    fontSize: 10,
    fontFamily: 'tahoma'
  },
  buttonIcon: {
    color: '#cd1212'
  }
}))

function humanFileSize(bytes, si = true) {
  let u, b = bytes, t = si ? 1000 : 1024;
  ['', si ? 'k' : 'K', ...'MGTPEZY'].find(x => (u = x, b /= t, b ** 2 < 1))
  return `${u ? (t * b).toFixed(1) : bytes} ${u}B`
}

function getExtension(name) {
  let re = /(?:\.([^.]+))?$/
  return re.exec(name)[1]
}

function getFileName(file) {
  return file.match(/^.*?([^\\/.]*)[^\\/]*$/)[1];
}

const DropZone = ({field, title, onChange, value, multiple, size, full, type, fileType}) => {
  const classes = useStyles()
  const [files, setFiles] = useState([])
  const [error, setError] = useState(false)

  const maxSize = size * 1000

  const {getRootProps, getInputProps} = useDropzone({
    accept: (type === 'image' || type === 'avatar') ? 'image/*' : fileType,
    multiple,
    maxSize,
    onDrop: acceptedFiles => {
      const processedFiles = acceptedFiles.map(file => {
        let fileType = file.type.split('/')[0]
        return Object.assign(file, {
          fileType,
          ext: getExtension(file.name),
          preview: URL.createObjectURL(file)
        })
      })

      const modifiedFiles = [...files, ...processedFiles]

      setFiles(modifiedFiles)

      onChange(modifiedFiles)
      setError(false)
    },
    onDropRejected: () => {
      setError(true)
    }
  })

  const uploadedFiles = () => {
    let val = value
    if(value && value.url && _.isArray(value.url)) {
      val = value.url
    }

    return _.isString(val) ? [val] : val
  }

  return (
    <>
      <Typography variant="body1">{title}</Typography>

      <section className={classes.container}>

        <If condition={type === "avatar"}>
          <Then>
            <div {...getRootProps({className: classes.avatarDropZone})}>
              <input {...getInputProps()} />

              <RiImageEditLine size={32} className={classes.uploadIcon}/>

              {files.length > 0 && (
                <>
                  {files.map(file => (
                    <img alt={title} src={file.preview} className={classes.avatarThumb}/>
                  ))}
                </>
              )}

              {(files.length === 0 && value) && (
                <>
                  {uploadedFiles().map(file => (
                    <img src={file} alt={title} className={classes.avatarThumb}/>
                  ))}
                </>
              )}
            </div>
          </Then>
          <Else>
            <div {...getRootProps({className: classes.dropZone})}>
              <input {...getInputProps()} />

              <BackupIcon fontSize={'large'}/>
              <Typography variant={'body1'}>{__('اسحب الملفات هنا أو تصفح', "Drag files here or Browse")}</Typography>
              <Typography variant={'caption'}>{__(`أقصي حجم للملف ${humanFileSize(maxSize)}`, `Allowed size ${humanFileSize(maxSize)}`)}</Typography>
            </div>
          </Else>
        </If>

        {error && <Typography variant={'body2'} style={{color: '#d50000', fontSize: 11, textAlign: 'center', marginTop: 5}}>{__('بعض الملفات اكبر من الحد الاقصى للحجم', 'Some files are larger than maximum size')}</Typography>}

        <If condition={type !== "avatar"}>
          <Then>
            {files.length > 0 && <div className={classes.selectedFiles}>
              <Table size={'small'}>
                <TableHead>
                  <TableRow>
                    <TableCell width={30}/>
                    <TableCell><strong>{__('اسم الملف', 'File Name')}</strong></TableCell>
                    <TableCell width={80}><strong>{__('الحجم', 'Size')}</strong></TableCell>
                    <TableCell align="center" width={30}><strong>{__('اجراء', 'Action')}</strong></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {files.map(file => (
                    <TableRow>
                      <TableCell>
                        {file.fileType === "image" ? <img alt={title} src={file.preview} className={classes.thumb}/> : <div className={classes.fileThumb}>{file.ext}</div>}
                      </TableCell>
                      <TableCell><Typography noWrap variant={'caption'}>{file.name}</Typography></TableCell>
                      <TableCell><Typography variant={'body2'}>{humanFileSize(file.size)}</Typography></TableCell>
                      <TableCell align="center">
                        <Button variant="text" size={'small'} onClick={() => {
                          const modifiedFiles = [...files.filter(x => x !== file)]
                          setFiles(modifiedFiles)
                          onChange(modifiedFiles)
                        }}>
                          <RiCloseLine size={20} className={classes.buttonIcon} />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>}

            {(value && files.length === 0) && <div className={classes.selectedFiles}>
              <Table size={'small'}>
                <TableHead>
                  <TableRow>
                    <TableCell width={30}/>
                    <TableCell><strong>{__('اسم الملف', 'File Name')}</strong></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {uploadedFiles().map(file => (
                    <>
                      {_.isString(file) && (
                        <TableRow>
                          <TableCell>
                            {['jpg', 'jpeg', 'png', 'gif', 'ico'].includes(getExtension(file)) ? <img src={file} alt={title} className={classes.thumb}/> : <div className={classes.fileThumb}>{getExtension(file)}</div>}
                          </TableCell>
                          <TableCell><Typography noWrap variant={'caption'}>{getFileName(file)}</Typography></TableCell>
                        </TableRow>
                      )}
                    </>
                  ))}
                </TableBody>
              </Table>
            </div>}
          </Then>
        </If>
      </section>
    </>
  )
}

const FormUpload = ({field, title, value, size, multiple = false, full, type, fileType}) => {
  const maxSize = size || formOptions.maxUpload
  return (
    <>
      <Field name={field}>
        {props => (
          <div>
            <DropZone {...props.input} size={maxSize} multiple={multiple} full={full} type={type} fileType={fileType} title={title} value={value}/>
          </div>
        )}
      </Field>
    </>
  )
}

FormUpload.propTypes = {
  field: PropTypes.string,
  type: PropTypes.string,
  title: PropTypes.any,
}

export default FormUpload
