import React, {useState} from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/styles'
import {TextField} from "mui-rff"
import FormLabel from "../../FormLabel"
import {If, Then, Else} from "react-if"
import MaskedInput from 'react-text-mask'
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import Input from "@material-ui/core/Input"

const useStyles = makeStyles(() => ({
  field: {}
}))

function regexBuilder(regex) {
  let output = []
  regex.split("").map( pattern => {
    let format = pattern
    switch (pattern) {
      case "A":
        format = /[0-9A-Za-z]/
        break
      case "B":
        format = /[0-9A-Z]/
        break
      case "C":
        format = /[A-Za-z]/
        break
      case "F":
        format = /[0-9]/
        break
      case "L":
        format = /[a-z]/
        break
      case "U":
        format = /[A-Z]/
        break
      case "W":
        format = /[0-9a-z]/
        break
    }

    output.push(format)
  })

  return output
}

function TextMaskCustom(props) {
  const {inputRef, mask, ...other} = props

  let inputMask = []

  switch (mask) {
    case 'iban':
      inputMask = regexBuilder('CCAAFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF')
      break
    case 'bank':
      inputMask = regexBuilder('FFFFFFFFFFFFFFFFFFFFFFFFFFFFFF')
      break
  }

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={inputMask}
      guide={false}
    />
  )
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
}

function getInputProps(mask) {
  if (mask) {
    return {
      inputComponent: TextMaskCustom,
      inputProps: {
        mask
      }
    }
  }

  return {}
}

const FormInput = ({field, title, isFilter, required, mask, mutators, onBlur, extras}) => {
  const classes = useStyles()

  return (
    <>
      <FormLabel title={title} isFilter={isFilter}/>

      <TextField
        {...extras}
        margin="none"
        required={required}
        name={field}
        className={classes.field}
        fullWidth
        label={!isFilter && title}
        placeholder={isFilter && title}
        variant="outlined"
        size={'small'}
        InputProps={getInputProps(mask)}
        onBlur={(e) => onBlur && onBlur(e.target.value, mutators, field)}
      />
    </>
  )
}

FormInput.propTypes = {
  field: PropTypes.string,
  type: PropTypes.string,
  title: PropTypes.any,
}

export default FormInput
