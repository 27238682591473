import React from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/styles'
import {TextField} from "mui-rff"
import FormLabel from "../../FormLabel"

const useStyles = makeStyles(() => ({
  field: {}
}))

const FormPassword = ({field, title, isFilter, required}) => {
  const classes = useStyles()

  return (
    <>
      <FormLabel title={title} isFilter={isFilter} />
      <TextField
        required={required}
        name={field}
        fullWidth
        label={!isFilter && title}
        placeholder={isFilter && title}
        type="password"
        variant="outlined"
        size={'small'}
        margin={'none'}
        initalValue={''}
      />
    </>
  )
}

FormPassword.propTypes = {
  field: PropTypes.string,
  type: PropTypes.string,
  title: PropTypes.any,
}

export default FormPassword
