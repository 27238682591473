import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/styles'
import {Grid, Modal, TextField, Typography} from "@material-ui/core"
import LocationOnIcon from "@material-ui/icons/LocationOn"
import LocationPicker from 'react-location-picker'
import parse from 'autosuggest-highlight/parse'
import PlacesAutocomplete, {geocodeByAddress, getLatLng,} from 'react-places-autocomplete'
import __ from "../../../../LanguageHelper"
import Autocomplete from "@material-ui/lab/Autocomplete"
import {usePosition} from 'use-position'
import Geocode from "react-geocode"
import Button from "@material-ui/core/Button"
import {Field} from "react-final-form"

const useStyles = makeStyles((theme) => ({
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 500,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%',
    backgroundColor: '#fff',
    padding: theme.spacing(2)
  },
  hint: {
    marginBottom: 10
  },
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
  locationField: {
    padding: 5,
    border: '1px solid #dedede',
    borderRadius: 5,
    cursor: 'pointer',
    marginTop: 5,
  },
  buttonContainer: {
    marginTop: 10,
    textAlign: 'center'
  }
}))

const LocationModal = ({title, place, onChange}) => {
  const classes = useStyles()
  const [address, setAddress] = useState('')
  const [visible, setVisible] = useState(false)

  const {latitude, longitude, timestamp, accuracy, error} = usePosition(true)
  const [position, setPosition] = useState(place || {
    lat: 24.726803,
    lng: 46.6020829
  })

  useEffect(() => {
    if (latitude && longitude && !place) {
      setPosition({
        lat: latitude,
        lng: longitude
      })
    }

    let lat = place ? place.lat : latitude,
      lng = place ? place.lng : longitude

    if(lat && lng) {
      Geocode.setApiKey("AIzaSyBp3L9Mew8KdynG0L28hszESwxbhuOROzE")
      Geocode.fromLatLng(lat, lng).then(
        response => {
          const address = response.results[0].formatted_address
          setAddress(address)
        },
        error => {
          console.error(error)
        }
      )
    }
  }, [latitude, longitude, place])

  const handleLocationChange = ({position, address}) => {
    setAddress(address)
    setPosition(position)
  }

  const handleSelect = address => {
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => setPosition(latLng))
      .catch(error => console.error('Error', error))
  }

  return (
    <>
      <Typography variant="body1">
        {title}
      </Typography>
      <div className={classes.locationField} onClick={() => setVisible(true)}>
        <Grid container alignItems="center">
          <Grid item>
            <LocationOnIcon className={classes.icon}/>
          </Grid>
          <Grid item xs>
            <Typography variant="body1" color="textSecondary">
              {address || __('اضغط لاختيار المكان', 'Click to select location')}
            </Typography>
          </Grid>
        </Grid>
      </div>

      <Modal
        onClose={() => setVisible(false)}
        open={visible}
      >
        <div className={classes.modal}>
          <PlacesAutocomplete
            value={address}
            onChange={setAddress}
            onSelect={handleSelect}
          >
            {({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
              <div>
                <Autocomplete
                  getOptionLabel={option => (typeof option === 'string' ? option : option.description)}
                  filterOptions={x => x}
                  options={suggestions}
                  autoComplete
                  includeInputInList
                  freeSolo
                  disableOpenOnFocus
                  value={address}
                  renderInput={params => (
                    <TextField
                      {...params}
                      size={'small'}
                      variant="outlined"
                      fullWidth
                      {...getInputProps({
                        placeholder: __('ابحث عن المكان...', 'Search Places...'),
                        className: 'location-search-input',
                      })}
                    />
                  )}
                  renderOption={option => {
                    // console.log(option)
                    const matches = option.matchedSubstrings
                    const parts = parse(
                      option.formattedSuggestion.mainText,
                      matches.map(match => [match.offset, match.offset + match.length]),
                    )

                    return (
                      <Grid container alignItems="center" {...getSuggestionItemProps(option)}>
                        <Grid item>
                          <LocationOnIcon className={classes.icon}/>
                        </Grid>
                        <Grid item xs>
                          {parts.map((part, index) => (
                            <span key={index} style={{fontWeight: part.highlight ? 700 : 400}}>{part.text}</span>
                          ))}

                          <Typography variant="body2" color="textSecondary">
                            {option.formattedSuggestion.secondaryText}
                          </Typography>
                        </Grid>
                      </Grid>
                    )
                  }}
                />
              </div>
            )}
          </PlacesAutocomplete>

          <Typography
            className={classes.hint}
            variant="body2"
          >
            {__('يمكنك البحث عن المكان الذي تريد او تحريك العلامة الحمراء للمكان الذي تريد', 'You can search for a place or drag the red dot to your desired location')}
          </Typography>

          <LocationPicker
            containerElement={<div style={{height: '100%'}}/>}
            mapElement={<div style={{height: '400px'}}/>}
            defaultPosition={position}
            onChange={handleLocationChange}
          />

          <div className={classes.buttonContainer}>
            <Button onClick={() => {
              setVisible(false)
              onChange(position)
            }} size={"small"} color="primary" autoFocus>
              {__('أختر هذا المكان', 'Select this place')}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  )

}

const FormLocation = ({field, title, value, place}) => {

  return (
    <>
      <Field name={field}>
        {props => (
          <div>
            <LocationModal {...props.input} field={field} title={title} value={value} place={place} />
          </div>
        )}
      </Field>
    </>
  )
}

FormLocation.propTypes = {
  field: PropTypes.string,
  type: PropTypes.string,
  title: PropTypes.any,
}

export default FormLocation
