import React from 'react'
import {makeStyles} from '@material-ui/styles'
import FormLabel from "../../FormLabel"
import {Typography} from "@material-ui/core"

const useStyles = makeStyles(() => ({
  field: {}
}))

const FormDisplay = ({field, title, required, text}) => {
  const classes = useStyles()

  return (
    <>
      <FormLabel title={title} isFilter={true}/>
      <Typography variant="subtitle2">
        <pre style={{ fontFamily: 'inherit' }}>
          {text}
        </pre>
      </Typography>
    </>
  )
}

export default FormDisplay
