import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/styles'
import {Controller} from "react-hook-form"
import {TwitterPicker} from "react-color"
import InputLabel from "@material-ui/core/InputLabel"
import FormLabel from "../../FormLabel"
import {Field} from "react-final-form"
import _ from 'lodash'

const useStyles = makeStyles((theme) => ({
  field: {},
  label: {
    marginBottom: theme.spacing(2)
  },
}))

const ColorPicker = ({field, title, value, isFilter, onChange}) => {
  const [color, setColor] = useState(value || '#C9494F')

  return (
    <TwitterPicker
      label={!isFilter && title}
      placeholder={isFilter && title}
      colors={['#C9494F', '#2192FD', '#955E88', '#BF6149', '#81AA40', '#3678A3', '#E18F00', '#68BCAC', '#55A47E']}
      color={color}
      onChangeComplete={(selectedColor) => {
        setColor(selectedColor.hex)
        onChange(selectedColor.hex)
      }}
    />
  )
}

const FormColor = ({field, title, value, isFilter, required, preview}) => {
  return (
    <>
      <FormLabel title={title} isFilter={true} />
      <div style={{marginBottom: 15}} />
      <Field name={field} required={required}>
        {props => (
          <div>
            <ColorPicker {...props.input} value={value} title={title} field={field} isFilter={isFilter} />
          </div>
        )}
      </Field>

      {preview && preview(value)}
    </>
  )
}

FormColor.propTypes = {
  field: PropTypes.string,
  type: PropTypes.string,
  title: PropTypes.any,
}

export default FormColor
