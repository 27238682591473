import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import moment from 'moment'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {Box, Divider, Grid, Paper, Table, TableBody, TableCell, TableFooter, TableHead, TableRow, Typography} from '@material-ui/core'
import './print.scss'
import * as numeral from "numeral"
import {pdfOptions} from "../../../../utils/globals"
import __ from "../../../LanguageHelper"

moment.locale('admin-utils')

class PrintableReport extends React.Component {
  render() {
    const {
      data,
      headers,
      className,
      title,
      sumData,
      columnObject
    } = this.props

    const {company, logo, logoStyle} = pdfOptions

    return (
      <Paper
        className={clsx(className)}
        {...this.props}
      >
          <Box p={2}>
            <Box display={'flex'} justifyContent={'center'}>
              <img src={`/images/${logo}`} style={{marginBottom: 20, ...logoStyle}}/>
            </Box>

            <Box px={3}>
              <Grid
                container
                justify="space-between"
                alignItems={'center'}
              >
                <Grid item>
                  <Typography
                    variant="h5"
                    color="textPrimary"
                  >
                    <strong>{company}</strong>
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="h5"
                    color="textPrimary"
                  >
                    <strong>{title}</strong>
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="caption">
                    {__('تاريخ الطباعة', 'Printing Date')}
                  </Typography>
                  <Typography
                    align="right"
                    variant="h6"
                    color="textPrimary"
                  >
                    {moment().format('DD-MM-YYYY hh:mm A')}
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            <Box my={2}>
              <Divider/>
            </Box>

            <Table size={'small'}>
              <TableHead>
                <TableRow>
                  {headers.map(header => (
                    <TableCell>
                      <div className={'nowrap'}>
                        <strong>{header}</strong>
                      </div>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row, index) => {
                  return (
                    <TableRow key={index}>
                      {row.map(({value, width}) => (
                        <TableCell width={width}>
                          {value}
                        </TableCell>
                      ))}
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </Box>

        <Box width={400}>
          <Table size={'small'}>
            <TableFooter>
              {sumData.map(sum => {
                const column = columnObject.find(i => i.field === sum.column)
                return (
                  <TableRow>
                    <TableCell/>
                    <TableCell>
                      <strong>{column.sum_title || column.title}</strong>
                    </TableCell>
                    <TableCell align="right">
                      {column.sum_before || ""} {sum.value} {column.sum_after || ""}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableFooter>
          </Table>
        </Box>
      </Paper>
    )
  }
}

PrintableReport.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  data: PropTypes.array.isRequired,
  headers: PropTypes.array.isRequired,
}

export default PrintableReport
