import {CardContent, colors, Grid, Typography} from "@material-ui/core"
import React, {useState} from "react"
import FormInput from "./FormInput"
import {makeStyles} from "@material-ui/styles"
import FormDate from "./FormDate"
import FormTime from "./FormTime"
import FormNumber from "./FormNumber"
import FormTextarea from "./FormTextarea"
import FormPassword from "./FormPassword"
import FormCurrency from "./FormCurrency"
import FormSwitch from "./FormSwitch"
import FormColor from "./FormColor"
import FormUpload from "./FormUpload"
import FormPicker from "./FormPicker"
import FormLocation from "./FormLocation"
import FormRadios from "./FormRadios"
import FormCheckboxes from "./FormCheckboxes"
import FormEditor from "./FormEditor"

import clsx from "clsx"
import {useSelector} from "react-redux"
import FormMonth from "./FormMonth"
import * as uuid from "uuid"
import {OnChange} from "react-final-form-listeners"
import _ from "lodash"
import FormDisplay from "./FormDisplay"
import FormRate from "./FormRate"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import __ from "../../../LanguageHelper"
import TableBody from "@material-ui/core/TableBody"
import Table from "@material-ui/core/Table"
import Button from "@material-ui/core/Button"
import Box from "@material-ui/core/Box"

const useStyles = makeStyles((theme) => ({
  column: {
    paddingBottom: '10px!important'
  },
  error: {
    color: colors.red.A700
  },
  hint: {},
  containerBox: {
    display: 'flex'
  },
  addButton: {
    marginLeft: theme.spacing(1.5),
    height: '37.6px'
  },
  deleteButton: {
    color: colors.red.A700
  }
}))

const FormRender = ({fields, repeatable, onRepeatableAdd, onRepeatableDelete, repeatableValues, flattenedFields, control, className, values, errors, isFilter, form}) => {
  const classes = useStyles()
  const {user: {clinic_id}} = useSelector((state) => state.session)
  const [pickerData, setPickerData] = useState({})

  const renderField = (item) => {
    let {type, onChange} = item
    let output

    item.isFilter = isFilter

    if (values && values[item.field]) {
      item.value = values[item.field]
    } else {
      delete item.value
    }

    item.form = form
    item.mutators = form.mutators

    if (Array.isArray(item.rules) && item.rules.includes("required")) {
      item.required = true
    }

    switch (type) {
      case 'string':
        output = <FormInput {...item} control={control}/>
        break
      case 'location':
        let position = null
        if (values.latitude && values.longitude) {
          position = {
            lat: values.latitude,
            lng: values.longitude
          }
        }
        output = <FormLocation {...item} place={position}/>
        break
      case 'date':
        output = <FormDate {...item}/>
        break
      case 'month':
        output = <FormMonth {...item}/>
        break
      case 'time':
        output = <FormTime {...item}/>
        break
      case 'numeric':
        output = <FormNumber {...item}/>
        break
      case 'switch':
        output = <FormSwitch {...item}/>
        break
      case 'text':
        output = <FormTextarea {...item}/>
        break
      case 'currency':
        output = <FormCurrency {...item}/>
        break
      case 'editor':
        output = <FormEditor {...item}/>
        break
      case 'password':
        output = <FormPassword {...item}/>
        break
      case 'dropdown':
      case 'select':
        output = <FormPicker {...item} values={values} onLoad={(data) => setPickerData({...pickerData, [item.field]: data})}/>
        break
      case 'checkbox':
        output = <FormCheckboxes {...item} values={values}/>
        break
      case 'radio':
        output = <FormRadios {...item} values={values}/>
        break
      case 'color':
        output = <FormColor {...item}/>
        break
      case 'display':
        output = <FormDisplay {...item}/>
        break
      case 'stars':
        output = <FormRate {...item}/>
        break
      case 'image':
      case 'avatar':
      case 'file':
        output = <FormUpload {...item} />
        break
      default:
        output = <FormInput {...item}/>
    }

    if (type !== "dropdown" && onChange) {
      output = (
        <>
          <OnChange name={item.field}>
            {(v) => {
              let newData = onChange(v, values)

              if (_.isArray(newData)) {
                newData.map(d => {
                  item.mutators.setValue(d.field, d.value)
                })
              } else {
                item.mutators.setValue(newData.field, newData.value)
              }
            }}
          </OnChange>
          {output}
        </>
      )
    }

    return output
  }

  return (
    <CardContent className={className}>

      <Box className={classes.containerBox}>
        <Grid
          spacing={2}
          container
        >
          {fields.map((fieldItem, i) => {
            let isArray = Array.isArray(fieldItem)
            let columns = 12
            if (isArray) {
              columns = 12 / fieldItem.filter(i => !!i).length
            } else {
              fieldItem = [fieldItem]
            }

            return (
              <>
                {fieldItem.filter(i => !!i).map((field, index) => {

                  // Dependency
                  if (field && field.dependency && _.isArray(field.dependency)) {
                    let shouldRender = true
                    field.dependency.map(({field, compare, value}) => {
                      let operator
                      switch (compare) {
                        case '=':
                          operator = '!=='
                          break
                        case '!=':
                          operator = '==='
                          break
                        case '>':
                          operator = '<'
                          break
                        case '<':
                          operator = '>'
                          break
                        case 'in':
                          operator = 'in'
                          break
                        case 'not_in':
                          operator = 'not_in'
                          break
                      }

                      // TODO: IN, NOT IN

                      if (operator === 'in' && _.isArray(value)) {
                        if (value.includes(values[field])) {
                          shouldRender = false
                        }
                      } else {
                        if (eval(`${_.isString(values[field]) ? `'${values[field]}'` : values[field]}
                        ${operator}
                        ${_.isString(value) ? `'${value}'` : value}`)) {
                          shouldRender = false
                        }
                      }
                    })

                    if (!shouldRender) return null
                  }

                  return (
                    <Grid
                      item
                      md={field.columns ? field.columns : columns}
                      xs={12}
                      className={clsx(classes.column, clinic_id && field.field)}
                    >
                      {renderField(field)}

                      {field.hint && <Typography variant="caption" className={classes.hint}>{field.hint}</Typography>}

                      {(errors && errors[field.field]) &&
                      <div>
                        <Typography variant="caption" className={classes.error}>
                          {errors[field.field][0]}
                        </Typography>
                      </div>}
                    </Grid>
                  )
                })}
              </>
            )
          })}
        </Grid>

        {repeatable && (
          <Button onClick={() => onRepeatableAdd()} color="primary" variant={'outlined'} className={classes.addButton}>
            {__('اضافة', 'ADD')}
          </Button>
        )}
      </Box>

      {repeatable && (
        <>
          <Table size={'small'} style={{marginTop: 10}}>
            <TableHead>
              <TableRow>
                {flattenedFields.map((fieldItem, i) => {
                  return (
                    <TableCell>{fieldItem.title}</TableCell>
                  )
                })}
                <TableCell width={50}>{__('اجراء', 'Action')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {repeatableValues.map((v, index) => {
                if (!repeatableValues[index]) return null
                return (
                  <TableRow>
                    {flattenedFields.map((fieldItem, i) => {
                      let title = repeatableValues[index][fieldItem.field]

                      if(fieldItem.type === 'dropdown' && pickerData[fieldItem.field]) {
                        title = pickerData[fieldItem.field].find(i => i.id === title)[fieldItem.label]
                      }

                      return (
                        <TableCell>{title}</TableCell>
                      )
                    })}
                    <TableCell width={50}>
                      <Button onClick={() => onRepeatableDelete(index)} color="primary" size={'small'} variant={'text'} className={classes.deleteButton}>
                        {__('حذف', 'REMOVE')}
                      </Button>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </>
      )}
    </CardContent>
  )
}

export default FormRender
