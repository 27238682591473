import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/styles'
import {EditorState, convertToRaw, ContentState} from 'draft-js'
import {Editor} from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import FormLabel from "../../FormLabel"

const useStyles = makeStyles(() => ({
  field: {},
  editorWrapper: {},
  editor: {
    border: '1px solid #F1F1F1',
    minHeight: 150,
    maxHeight: 300,
    paddingLeft: 10,
    paddingRight: 10,
  }
}))

const FormEditor = ({field, title, mutators, value}) => {
  const classes = useStyles()
  const [editorState, setEditorState] = useState(EditorState.createEmpty())

  useEffect(() => {
    if(value) {
      const contentBlock = htmlToDraft(value)
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
        const editorState = EditorState.createWithContent(contentState)
        setEditorState(editorState)
      }
    }
  }, [value])

  return (
    <>
      <FormLabel title={title} isFilter={true}/>
      <Editor
        editorState={editorState}
        wrapperClassName={classes.editorWrapper}
        editorClassName={classes.editor}
        onEditorStateChange={(state) => {
          setEditorState(state)
        }}
        onBlur={() => {
          mutators.setValue(field, draftToHtml(convertToRaw(editorState.getCurrentContent())))
        }}
        stripPastedStyles={true}
      />
    </>
  )
}

FormEditor.propTypes = {
  field: PropTypes.string,
  type: PropTypes.string,
  title: PropTypes.any,
}

export default FormEditor
