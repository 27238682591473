import React from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/styles'
import {Controller} from "react-hook-form"
import {TextField} from "mui-rff"
import InputAdornment from "@material-ui/core/InputAdornment"
import useTheme from "@material-ui/core/styles/useTheme"
import FormLabel from "../../FormLabel"
import __ from "../../../../LanguageHelper"
import {currency} from 'src/utils/globals'

const useStyles = makeStyles(() => ({
  field: {}
}))

const FormCurrency = ({field, title, isFilter, required, selectable, readOnly}) => {
  const classes = useStyles()

  return (
    <>
      <FormLabel title={title} isFilter={isFilter} />
      <TextField
        required={required}
        margin="none"
        name={field}
        fullWidth
        label={!isFilter && title}
        placeholder={isFilter && '0'}
        type="number"
        variant="outlined"
        InputProps={{
          endAdornment: <InputAdornment position="end">{currency}</InputAdornment>,
          readOnly
        }}
        size={'small'}
        onFocus={event => {
          selectable && event.target.select()
        }}
      />
    </>
  )
}

FormCurrency.propTypes = {
  field: PropTypes.string,
  type: PropTypes.string,
  title: PropTypes.any,
}

export default FormCurrency
