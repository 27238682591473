import React, {useRef} from 'react'
import {Box, Button, makeStyles} from '@material-ui/core'
import {Page} from "src/admin-utils"
import ReactToPrint from "react-to-print"
import __ from "../../../LanguageHelper"
import PrintableReport from "./PrintableReport"

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}))

class PrintableDocumentClass extends React.Component {
  render() {
    const {component: Component} = this.props
    return (
      <Component {...this.props} />
    )
  }
}

function PrintableDocument({component, title, ...rest}) {
  const classes = useStyles()
  const componentRef = useRef()

  return (
    <Page
      hideManagement
      title={title}
      className={classes.root}
      left={() => (
        <ReactToPrint
          trigger={() => (
            <Button
              variant="contained"
              color={'secondary'}
            >
              {__('طباعة', 'Print')}
            </Button>
          )}
          content={() => componentRef.current}
        />
      )}
    >
      <Box>
        <PrintableDocumentClass
          ref={componentRef}
          component={component}
          {...rest}
        />
      </Box>
    </Page>
  )
}

export default PrintableDocument
