const arabic = {
  datatable: {
    body: {
      emptyDataSourceMessage: 'عفوا لا يوجد نتائج',
      filterRow: {
        filterTooltip: 'فلترة النتائج'
      }
    },
    toolbar: {
      exportTitle: 'تصدير البيانات',
      exportName: 'تصدير البيانات',
      nRowsSelected: 'حددت {0} صفوف',
      searchPlaceholder: 'بحث'
    },
    pagination: {
      labelRowsSelect: 'نتائج في الصفحة',
      labelDisplayedRows: ' {from}-{to} من إجمالي {count} نتائج',
      firstTooltip: 'الصفحة الأولي',
      previousTooltip: 'الصفحة السابقة',
      nextTooltip: 'الصفحة التالية',
      lastTooltip: 'الصفحة السابقة'
    },
  }
}

export default arabic
