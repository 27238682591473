import React, {useRef} from 'react'
import {Box, Button, makeStyles} from '@material-ui/core'
import {Page} from "src/admin-utils"
import ReactToPrint from "react-to-print"
import __ from "../../../LanguageHelper"
import PrintableReport from "./PrintableReport"

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}))

function PrintingView({data, headers, title, sumData, columnObject}) {
  const classes = useStyles()
  const componentRef = useRef()

  return (
    <Page
      className={classes.root}
      title={__('طباعة التقرير', 'Printing Report')}
      left={() => (
        <ReactToPrint
          trigger={() => (
            <Button
              variant="contained"
              color={'secondary'}
            >
              {__('طباعة', 'Print')}
            </Button>
          )}
          content={() => componentRef.current}
        />
      )}
    >
      <Box>
        <PrintableReport
          ref={componentRef}
          title={title}
          headers={headers}
          data={data}
          sumData={sumData}
          columnObject={columnObject}
        />
      </Box>
    </Page>
  )
}

export default PrintingView
