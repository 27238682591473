import React, {
  Children,
  cloneElement,
  Component,
  useEffect,
  useState
} from "react"
import PropTypes from "prop-types"
import {makeStyles} from "@material-ui/styles"
import {Grid} from "@material-ui/core"
import {getDetails} from "../../API"
import "./DetailsContainer.scss"
import {useParams} from "react-router"
import {useSelector} from "react-redux"

const useStyles = makeStyles(() => ({
  root: {}
}))

const DetailsContainer = ({
                            className,
                            children,
                            endpoint,
                            params,
                            id,
                            updater,
                            container = true,
                            onFinish
                          }) => {
  const classes = useStyles()
  const {token} = useSelector(state => state.session)

  let urlParams = useParams()
  if (!id) {
    id = urlParams.id
  }

  let [data, setData] = useState()
  let [loading, setLoading] = useState(false)

  async function getData() {
    let response = await getDetails(endpoint, id, token, params)
    setData(response.data)

    onFinish && onFinish(response.data)
  }

  useEffect(() => {
    if (endpoint) {
      setLoading(true)
      getData().then(() => {
        setLoading(false)
      })
    }
  }, [updater])

  return (
    <>
      <Grid className={className} container={container} spacing={2}>
        {Children.map(children, (child, i) => {
          return cloneElement(child, {
            data,
            loading
          })
        })}
      </Grid>
    </>
  )
}

DetailsContainer.propTypes = {
  className: PropTypes.string
}

export default DetailsContainer
