const savedDirection = localStorage.getItem('direction')

export const isRTL = savedDirection === 'rtl'

const __ = (ar, en, replacers = {}) => {
  const savedDirection = localStorage.getItem('direction')
  let text = savedDirection === 'rtl' ? ar : en

  let replacersKeys = Object.keys(replacers)
  if(replacersKeys.length > 0) {
    replacersKeys.map((key) => {
      text = text.replace(`{${key}}`, replacers[key])
    })
  }

  return text
}

export const lang = (en, ar) => {
  return __(ar, en)
}

export default __
