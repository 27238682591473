import moment from "moment"
import {lang} from "../../../LanguageHelper"

const plurals = {
  s: ['أقل من ثانية', 'ثانية واحدة', ['ثانيتان', 'ثانيتين'], '%d ثوان', '%d ثانية', '%d ثانية'],
  m: ['أقل من دقيقة', 'دقيقة واحدة', ['دقيقتان', 'دقيقتين'], '%d دقائق', '%d دقيقة', '%d دقيقة'],
  h: ['أقل من ساعة', 'ساعة واحدة', ['ساعتان', 'ساعتين'], '%d ساعات', '%d ساعة', '%d ساعة'],
  d: ['أقل من يوم', 'يوم واحد', ['يومان', 'يومين'], '%d أيام', '%d يومًا', '%d يوم'],
  M: ['أقل من شهر', 'شهر واحد', ['شهران', 'شهرين'], '%d أشهر', '%d شهرا', '%d شهر'],
  y: ['أقل من عام', 'عام واحد', ['عامان', 'عامين'], '%d أعوام', '%d عامًا', '%d عام']
}

const pluralForm = function (n) {
  return n === 0 ? 0 : n === 1 ? 1 : n === 2 ? 2 : n % 100 >= 3 && n % 100 <= 10 ? 3 : n % 100 >= 11 ? 4 : 5
}

const pluralize = function (u) {
  return function (number, withoutSuffix, string, isFuture) {
    let f = pluralForm(number),
      str = plurals[u][pluralForm(number)]
    if (f === 2) {
      str = str[withoutSuffix ? 0 : 1]
    }
    return str.replace(/%d/i, number)
  }
}

const weekdays = 'Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday'.split('_')
const relativeTimeEn = {
  future : 'in %s',
  past : '%s ago',
  s : 'a few seconds',
  ss : '%d seconds',
  m : 'a minute',
  mm : '%d minutes',
  h : 'an hour',
  hh : '%d hours',
  d : 'a day',
  dd : '%d days',
  M : 'a month',
  MM : '%d months',
  y : 'a year',
  yy : '%d years'
}, relativeTimeAr = {
  future: 'بعد %s',
  past: 'منذ %s',
  s: pluralize('s'),
  ss: pluralize('s'),
  m: pluralize('m'),
  mm: pluralize('m'),
  h: pluralize('h'),
  hh: pluralize('h'),
  d: pluralize('d'),
  dd: pluralize('d'),
  M: pluralize('M'),
  MM: pluralize('M'),
  y: pluralize('y'),
  yy: pluralize('y')
}

moment.defineLocale('admin-utils', {
  meridiem: function (hour, minute, isLower) {
    if (hour < 12) {
      return lang('AM', 'صباحا')
    } else {
      return lang('PM', 'مساءا')
    }
  },
  months: [
    lang('January', 'يناير'),
    lang('February', 'فبراير'),
    lang('March', 'مارس'),
    lang('April', 'ابريل'),
    lang('May', 'مايو'),
    lang('June', 'يونيو'),
    lang('July', 'يوليو'),
    lang('August', 'أغسطس'),
    lang('September', 'سبتمبر'),
    lang('October', 'أكتوبر'),
    lang('November', 'نوفمبر'),
    lang('December', 'ديسمبر'),
  ],
  weekdays: lang(weekdays, [
    'الأحد',
    'الاثنين',
    'الثلاثاء',
    'الاربعاء',
    'الخميس',
    'الجمعة',
    'السبت',
  ]),
  relativeTime: lang(relativeTimeEn, relativeTimeAr),
})
