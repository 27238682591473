import React from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/styles'
import {TextField} from "mui-rff"
import InputAdornment from "@material-ui/core/InputAdornment"
import useTheme from "@material-ui/core/styles/useTheme"
import FormLabel from "../../FormLabel"

const useStyles = makeStyles((theme) => ({
  field: {},
  label: {
    marginBottom: theme.spacing(1),
    fontWeight: 600
  }
}))

const FormNumber = ({field, title, append, isFilter, required, selectable, readOnly, min, max}) => {
  const classes = useStyles()
  const {direction} = useTheme()

  const isRTL = direction === 'rtl'

  return (
    <>
      <FormLabel title={title} isFilter={isFilter}/>
      <TextField
        required={required}
        margin="none"
        name={field}
        fullWidth
        label={!isFilter && title}
        placeholder={isFilter && '0'}
        type="number"
        variant="outlined"
        InputProps={{
          endAdornment: append && <InputAdornment position="end">{isRTL ? append[1] : append[0]}</InputAdornment>,
          readOnly
        }}
        inputProps={{
          max,
          min
        }}
        size={'small'}
        onFocus={event => {
          selectable && event.target.select()
        }}
      />
    </>
  )
}

FormNumber.propTypes = {
  field: PropTypes.string,
  type: PropTypes.string,
  title: PropTypes.any,
}

export default FormNumber
