import React from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/styles'
import FormLabel from "../../FormLabel"
import {Switches} from "mui-rff"

const useStyles = makeStyles(() => ({
  field: {}
}))

const FormSwitch = ({field, title, options, isFilter, required, value}) => {
  const classes = useStyles()

  console.log(field, value)

  return (
    <>
      <FormLabel title={title} isFilter={isFilter} />
      <Switches
        label={!isFilter && title}
        placeholder={isFilter && title}
        name={field}
        required={required}
        data={options}
      />
    </>
  )
}

FormSwitch.propTypes = {
  field: PropTypes.string,
  type: PropTypes.string,
  title: PropTypes.any,
}

export default FormSwitch
