import React from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/styles'
import {TextField} from "mui-rff"
import FormLabel from "../../FormLabel"

const useStyles = makeStyles(() => ({
  field: {}
}))

const FormTextarea = ({field, title, isFilter, required, size = 4}) => {
  const classes = useStyles()

  return (
    <>
      <FormLabel title={title} isFilter={isFilter}/>
      <TextField
        required={required}
        margin="none"
        name={field}
        fullWidth
        multiline
        rows={size}
        label={!isFilter && title}
        placeholder={isFilter && title}
        variant="outlined"
        size={'small'}
      />
    </>
  )
}

FormTextarea.propTypes = {
  field: PropTypes.string,
  type: PropTypes.string,
  title: PropTypes.any,
}

export default FormTextarea
