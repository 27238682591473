import Drawer from "@material-ui/core/Drawer"
import React, {Component, createElement, lazy, memo, useRef} from "react"
import {makeStyles} from "@material-ui/styles"
import Box from "@material-ui/core/Box"
import Modal from "@material-ui/core/Modal"
import ReactToPrint from "react-to-print"
import Button from "@material-ui/core/Button"
import __ from "../../LanguageHelper"
import Page from "./Page"

const useStyles = makeStyles((theme) => ({
  drawer: {
    flexShrink: 0,
    maxWidth: '100%',
  },
  drawerPaper: {
    zIndex: theme.zIndex.drawer + 200,
    maxWidth: '100%'
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    justifyContent: 'flex-end',
  },
  filterTitle: {
    padding: theme.spacing(0, 3),
    marginTop: -25
  },
  formContainer: {
    paddingTop: theme.spacing(2),
    flex: 1
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%',
    backgroundColor: '#fff',
  },
}))

const DetailsDrawer = ({
                         showDetails,
                         component,
                         onClose,
                         drawerWidth = 1000,
                         direction = 'left',
                         modal = false,
                         clear = false,
                       }) => {

  const classes = useStyles()

  if (modal) {
    const style = clear ? {backgroundColor: 'transparent'} :{}
    return (
      <Modal
        open={showDetails}
        onClose={() => onClose()}
      >
        <Box width={drawerWidth} className={classes.modal} style={style}>
          {component}
        </Box>
      </Modal>
    )
  }

  return (
    <Drawer
      className={classes.drawer}
      variant="temporary"
      anchor={direction}
      open={showDetails}
      classes={{
        paper: classes.drawerPaper,
      }}
      onBackdropClick={() => {
        onClose()
      }}
    >
      <Box width={drawerWidth}>
        {component}
      </Box>
    </Drawer>
  )
}

const initialState = {
  showDetails: false,
  view: null,
  modal: false
}

class DetailsPanel extends Component {

  state = {
    showDetails: false
  }

  constructor(props) {
    super(props)
  }

  static detailsPanelInstance

  static show(params) {
    this.detailsPanelInstance.showDetails(params)
  }

  static hide(stay) {
    this.detailsPanelInstance.hideDetails(stay)
  }

  showDetails(params) {
    this.setState({showDetails: true, ...params})
  }

  hideDetails(stay) {
    this.setState({...initialState, stay})
  }

  render() {
    const {
      view,
      route,
      stay,
    } = this.state

    return (
      <DetailsDrawer
        {...this.state}
        component={view}
        route={route}
        onClose={() => {
          this.setState(initialState)

          // Stay in the current page
          if (stay) return

          // Back to previous page
          window.history.back()
        }}
      />
    )
  }
}

export default DetailsPanel
