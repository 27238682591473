import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/styles'
import {Controller} from "react-hook-form"
import {DatePicker, TextField} from 'mui-rff'
import moment, {max, min} from "moment"
import FormLabel from "../../FormLabel"
import _ from "lodash"
import {OnChange} from "react-final-form-listeners"
import * as uuid from "uuid"
import DateRangeIcon from '@material-ui/icons/DateRange'
import InputAdornment from "@material-ui/core/InputAdornment"

const useStyles = makeStyles(() => ({
  field: {}
}))

const FormDate = ({field, title, isFilter, required, waitValue, logic, values}) => {
  const classes = useStyles()
  const [disabled, setDisabled] = useState(!!waitValue)
  const [minDate, setMinDate] = useState(new Date('01/01/1900'))
  const [maxDate, setMaxDate] = useState(new Date('01/01/2100'))
  const [disablePast] = useState(logic ? logic.disablePast : false)
  const [disableFuture] = useState(logic ? logic.disableFuture : false)

  useEffect(() => {
    if (logic && _.isObject) {
      let {minDate, maxDate} = logic
      if (_.isNumber(minDate)) {
        if (minDate > 0) {
          setMinDate(moment().add(minDate, 'days').toDate())
        } else {
          setMinDate(moment().subtract(-minDate, 'days').toDate())
        }
      }

      if (_.isNumber(maxDate)) {
        if (maxDate > 0) {
          setMaxDate(moment().add(maxDate, 'days').toDate())
        } else {
          setMaxDate(moment().subtract(-maxDate, 'days').toDate())
        }
      }
    }
  }, [])

  return (
    <>
      {waitValue && <OnChange name={waitValue}>
        {(v, previous) => {
          if (v) {
            setDisabled(false)

            // Logic
            if (logic && _.isObject) {
              let {minDate, maxDate} = logic
              if (minDate === 'waitValue' || maxDate === 'waitValue') {
                let date = moment(v)
                if (date.isValid()) {
                  if (minDate === 'waitValue') setMinDate(date.add(1, 'day').toDate())
                  if (maxDate === 'waitValue') setMaxDate(date.subtract(1, 'day').toDate())
                }
              }

              if (_.isArray(minDate)) {
                let min = minDate[1]

                if (min > 0) {
                  setMinDate(moment(v).add(min, 'days').toDate())
                } else {
                  setMinDate(moment(v).subtract(-min, 'days').toDate())
                }
              }

              if (_.isArray(maxDate)) {
                let max = maxDate[1]
                if (max > 0) {
                  setMaxDate(moment(v).add(max, 'days').toDate())
                } else {
                  setMaxDate(moment(v).subtract(-max, 'days').toDate())
                }
              }
            }
          } else {
            setDisabled(true)
          }
        }}
      </OnChange>}

      <FormLabel title={title} isFilter={isFilter}/>
      <DatePicker
        disablePast={disablePast}
        disableFuture={disableFuture}
        minDate={minDate}
        maxDate={maxDate}
        disabled={disabled}
        required={required}
        name={field}
        fullWidth
        clearable
        label={!isFilter && title}
        placeholder={isFilter && ''}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <DateRangeIcon/>
            </InputAdornment>
          ),
        }}
        inputVariant="outlined"
        format="DD/MM/YYYY"
        margin="none"
        size={'small'}
        autoOk
      />
    </>
  )
}

FormDate.propTypes = {
  field: PropTypes.string,
  type: PropTypes.string,
  title: PropTypes.any,
}

export default FormDate
